import React from 'react';
import Divider from '@mui/material/Divider';

// import '../styles/App.css';
import '../styles/Footer.css';

export default function Footer() {
    return (
        <>
            <div className="foot">
                <h6 className="footer-text">
                    COPYRIGHT © 2023 FORT DEFIANCE FIREARMS TRAINING - ALL RIGHTS RESERVED.
                </h6>

            </div>
        </>
    )
}
